import { VStack, Text, Image, Box } from "@chakra-ui/react";
import logo from "../images/izr_logo.png";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
function PrivacyPolicies() {
  return (
    <VStack fontFamily={"Poppins"}>
      <Box
        margin={5}
        padding={10}
        borderRadius={"1.5vw"}
        shadow={"0px 0px 5px 2px lightgray"}
      >
        <Image src={logo} width={"10vw"}></Image>
      </Box>
      <Text padding={10}>
        <header>
          <h1 style={{ fontSize: "1.5vw", fontWeight: "bold" }}>
            Privacy Policy for IZR APP
          </h1>
        </header>

        <section>
          <h2 style={{ fontSize: "1.1vw", fontWeight: "bold" }}>
            Information We Do Not Collect
          </h2>
          <p>
            IZR does not collect, store, or share any personal information from
            users. We do not request or require users to provide any personal
            data, such as names, addresses, email addresses, or any other
            identifiable information.
          </p>
        </section>

        <section>
          <h2 style={{ fontSize: "1.1vw", fontWeight: "bold" }}>Usage Data</h2>
          <p>
            The App may collect and store anonymized usage data, such as device
            type, operating system, and general usage patterns. This information
            is solely used for improving the performance and user experience of
            the App and is not linked to any personally identifiable
            information.
          </p>
        </section>

        <section>
          <h2 style={{ fontSize: "1.1vw", fontWeight: "bold" }}>
            Third-Party Services
          </h2>
          <p>
            The App does not use any third-party services that collect personal
            information. We do not integrate any login services, analytics
            tools, or advertising platforms that may track or collect user data.
          </p>
        </section>

        <section>
          <h2 style={{ fontSize: "1.1vw", fontWeight: "bold" }}>Security</h2>
          <p>
            We take reasonable measures to protect the information collected by
            the App. However, please be aware that no method of transmission
            over the internet or electronic storage is completely secure, and we
            cannot guarantee the absolute security of your data.
          </p>
        </section>

        <section>
          <h2 style={{ fontSize: "1.1vw", fontWeight: "bold" }}>
            Changes to this Privacy Policy
          </h2>
          <p>
            We may update this Privacy Policy from time to time. Any changes
            will be effective immediately upon posting the updated Privacy
            Policy on this page.
          </p>
        </section>

        <section>
          <h2 style={{ fontSize: "1.1vw", fontWeight: "bold" }}>Contact Us</h2>
          <p>
            If you have any questions or concerns about this Privacy Policy,
            please contact us at{" "}
            <a
              style={{ fontWeight: "bold" }}
              href="mailto:mbellil262@gmail.com"
            >
              mbellil262@gmail.com
            </a>
            .
          </p>
        </section>

        <footer>
          <p>This Privacy Policy was last updated on 07.12.2023.</p>
        </footer>
      </Text>
    </VStack>
  );
}

export default PrivacyPolicies;
