import {
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
} from "@chakra-ui/react";
import React, { useState } from "react";

interface props {
  children: React.ReactNode;
  keys: string[];
}

function EditableList({ children, keys }: props) {

  
  return (
    <TableContainer>
      <Table variant="simple">
        <TableCaption>Islamisches Zentrum Regensburg ©</TableCaption>
        <Thead>
          <Tr>
            {keys.map((k, index) => (
              <Th key={index}>{k}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>{children}</Tbody>
      </Table>
    </TableContainer>
  );
}

export default EditableList;
