import * as React from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
  createHashRouter,
} from "react-router-dom";
import Home from "./Home";
import AppVerwaltung from "./app-verwaltung/AppVerwaltung";
import MeetingVerwaltung from "./MeetingVerwaltung";
import Administration from "./Administration";
import PrivacyPolicies from "./PrivacyPolicies";
import SchuleVerwaltung from "./schule/SchuleVerwaltung";
import ScreenController from "./ScreenController";
import AppWebung from "./AppWebung";
import BilschirmConfig from "./BilschirmConfig";

const router = createHashRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/izrApp",
    element: <AppVerwaltung />,
  },
  {
    path: "/appflyer",
    element: <AppWebung />,
  },
  {
    path: "/controller",
    element: <ScreenController />,
  },
  {
    path: "/izrSchule",
    element: <SchuleVerwaltung />,
  },
  {
    path: "/izrMeeting",
    element: <MeetingVerwaltung />,
  },
  {
    path: "/admins",
    element: <Administration />,
  },
  {
    path: "/appPrivacyPolicies",
    element: <PrivacyPolicies />,
  },
  {
    path: "/conifgs",
    element: <BilschirmConfig />,
  },
]);

export default router;
