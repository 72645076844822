import { HStack, Image } from "@chakra-ui/react";
import React from "react";
import appflyer from "../images/APPFlyer.png";
function AppWebung() {
  return (
    <HStack width={"100vw"} height={"100vh"}>
      <Image src={appflyer}></Image>
    </HStack>
  );
}

export default AppWebung;
