import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Select,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import IZRButton from "../IZRButton";
import axios from "axios";
import izr_server from "../../config/urls";

interface props {
  onSubmit: () => void;
  name?: string;
}
interface student {
  Name: string;
  Vorname: string;
  Telefon: string;
  Klasse: number;
  Gebutsdatum: string;
}

function SchulerForm({ onSubmit, name }: props) {
  const klassen = ["1", "2", "3", "4", "5", "6"];
  const [student, setStudent] = useState<student>({
    Name: "",
    Vorname: "",
    Telefon: "",
    Klasse: 0,
    Gebutsdatum: "",
  });
  useEffect(() => {
    if (name) {
      console.log(name);
      axios
        .post(izr_server.url + "/getStudents/", { key: "Vorname", value: name })
        .then((response) => {
          setStudent(response.data);
        });
    }
  }, [name]);

  const handleSubmit = () => {
    axios
      .post(izr_server.url + "/updateStudent/", {
        key: "Vorname",
        value: name ? name : student.Vorname,
        student: student,
      })
      .then((response) => {
        setStudent(response.data);
      });
    console.log(student);
    onSubmit();
  };
  const handleDelete = () => {
    axios
      .post(izr_server.url + "/updateStudent/", {
        key: "Vorname",
        value: name ? name : student.Vorname,
        student: "delete",
      })
      .then((response) => {
        setStudent(response.data);
      });
    console.log(student);
    onSubmit();
  };
  return (
    <VStack fontFamily={"Poppins"} justifyContent={"left"} margin={5}>
      <FormControl isRequired>
        <VStack gap={2} width={"100%"}>
          <FormLabel width={"100%"} height={"100%"}>
            Vorname
          </FormLabel>
          <Input
            onChange={(e) =>
              setStudent({ ...student, Vorname: e.currentTarget.value })
            }
            value={student?.Vorname}
            placeholder="Vorname"
          />
          <FormLabel width={"100%"} height={"100%"}>
            Nachname
          </FormLabel>
          <Input
            onChange={(e) =>
              setStudent({ ...student, Name: e.currentTarget.value })
            }
            value={student?.Name}
            placeholder="Name"
          />
          <FormLabel width={"100%"} height={"100%"}>
            Geburtsdatum
          </FormLabel>
          <Input
            onSelect={(e) =>
              setStudent({ ...student, Gebutsdatum: e.currentTarget.value })
            }
            value={student?.Gebutsdatum}
            placeholder="Geburtsdatum"
          />
          <FormLabel width={"100%"} height={"100%"}>
            Klasse
          </FormLabel>
          {/* <FormLabel height={"100%"}>Vatername</FormLabel> */}
          <Select
            onChange={(e) =>
              setStudent({ ...student, Klasse: Number(e.currentTarget.value) })
            }
            value={student?.Klasse}
          >
            {klassen.map((kl) => (
              <option value={kl}>{kl}</option>
            ))}
          </Select>
          <FormLabel width={"100%"} height={"100%"}>
            Tel. Nummer
          </FormLabel>
          <Input
            onChange={(e) =>
              setStudent({ ...student, Telefon: e.currentTarget.value })
            }
            value={student?.Telefon}
            placeholder="Tel. Nummer"
          />
        </VStack>
      </FormControl>
      {/* <Input onChange={() => setStudent({...s tudent,})} value={student.} placeholder="Vatername" /> */}
      <IZRButton onClick={handleSubmit} title="Anwenden"></IZRButton>
      <IZRButton onClick={handleDelete} title="Löschen"></IZRButton>
    </VStack>
  );
}

export default SchulerForm;
