import React, { useEffect, useState } from "react";
import NavBar from "../NavBar";
import { Grid, GridItem, filter } from "@chakra-ui/react";
import SchuleParams from "./SchuleParams";
import EditableTableRow from "./EditableTableRow";
import EditableList from "./EditableList";
import SchulerForm from "./SchulerForm";
import izr_server from "../../config/urls";
import axios, { all } from "axios";
import FormModal from "./FormModal";
import Schueler from "./Schueler";
import Aside from "./Aside";
import JahreKlassenVerwaltung from "./JahreKlassenVerwaltung";

export interface student {
  Name: string;
  Vorname: string;
  Telefon: string;
  Klasse: number;
  Gebutsdatum: string;
}

function SchuleVerwaltung() {
  const [currentYear, setCurrentYear] = useState("");
  const [currentButton, setCurrentButton] = useState("Schüler");
  const [onPräsenz, setOnPräsenz] = useState(true);

  const [Klasse, setKlasse] = useState(0);
  //Klassen
  const [schultag, setSchultag] = useState(
    new Date().getMonth() +
      1 +
      "-" +
      new Date().getDate() +
      "-" +
      new Date().getFullYear()
  );

  //Leherer
  //Statistik
  //Zahlung
  return (
    <>
      <NavBar page={"Schule"} />
      <Grid
        templateAreas={`
        "aside parameter"
        "aside body"
        `}
        // width={"100vw"}
        height={"75vh"}
        gridTemplateRows={
          currentButton === "Schüler" || currentButton === "Lehrer"
            ? "1fr 8fr"
            : "0fr 1fr"
        }
        gridTemplateColumns={"1fr 4fr"}
        gap={5}
        margin={2}
        fontFamily={"Poppins"}
      >
        <GridItem area={"parameter"}>
          {(currentButton === "Schüler" || currentButton === "Lehrer") && (
            <SchuleParams
              what={currentButton}
              onJahrSelect={(jahr) => {
                setCurrentYear(jahr);
              }}
              onPräsenz={() => {
                setOnPräsenz(true);
              }}
              onBearbeiten={() => setOnPräsenz(false)}
              onChangeClass={(klasse) => setKlasse(klasse)}
              klasse={Klasse}
              presense={onPräsenz}
              onChnageDay={(day) => {
                setSchultag(day);
              }}
            ></SchuleParams>
          )}
        </GridItem>
        <GridItem area={"aside"}>
          <Aside onSwitch={(value) => setCurrentButton(value)} />
        </GridItem>
        <GridItem
          borderTop={"1px solid lightgray"}
          borderLeft={"1px solid lightgray"}
          borderRadius={10}
          overflow={"scroll"}
          area={"body"}
          padding={5}
        >
          {(currentButton === "Schüler" || currentButton === "Lehrer") && (
            <Schueler
              setKlasse={(kl) => setKlasse(kl)}
              onPräsenz={onPräsenz}
              currentYear={currentYear}
              Klasse={Klasse}
              schultag={schultag}
            ></Schueler>
          )}
          {currentButton === "Schuljahren/Klassen verwalten" && (
            <JahreKlassenVerwaltung />
          )}
        </GridItem>
      </Grid>
    </>
  );
}

export default SchuleVerwaltung;
