import React, { useState } from 'react';
import { Box, Button, FormControl, FormLabel, Textarea } from '@chakra-ui/react';
import axios from 'axios';
import izr_server from '../config/urls';

const HadithForm = () => {
  // Create state variables for each input field
  const [hadithDeutsch, setHadithDeutsch] = useState('');
  const [hadithArabisch, setHadithArabisch] = useState('');
  const [uberlieferungDeutsch, setUberlieferungDeutsch] = useState('');
  const [uberlieferungArabisch, setUberlieferungArabisch] = useState('');

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Create an object with the data
    const data = {
      hadith_de : hadithDeutsch,
      hadith_ar : hadithArabisch,
      data_de : uberlieferungDeutsch,
      data_ar : uberlieferungArabisch,
    };

    // Make the POST request
    try {
      const response = await axios.post(izr_server.url + "/tasks/hadith/", data);
      console.log(response.data);

      // Clear the input fields after submission
      setHadithDeutsch('');
      setHadithArabisch('');
      setUberlieferungDeutsch('');
      setUberlieferungArabisch('');
    } catch (error) {
      console.error('Error posting data', error);
    }
  };

  // Check if any field is empty
  const isSubmitDisabled = !hadithDeutsch || !hadithArabisch || !uberlieferungDeutsch || !uberlieferungArabisch;

  return (
    <Box fontFamily={"Poppins"} as="form" onSubmit={handleSubmit} p={4} borderWidth={1} borderRadius={8} boxShadow="lg">
      <FormControl id="hadith-deutsch" mb={4} isRequired>
        <FormLabel>Hadith Deutsch</FormLabel>
        <Textarea
          value={hadithDeutsch}
          onChange={(e) => setHadithDeutsch(e.target.value)}
          placeholder="Enter Hadith in Deutsch"
        />
      </FormControl>

      <FormControl id="hadith-arabisch" mb={4} isRequired>
        <FormLabel>Hadith Arabisch</FormLabel>
        <Textarea
          value={hadithArabisch}
          onChange={(e) => setHadithArabisch(e.target.value)}
          placeholder="Enter Hadith in Arabisch"
        />
      </FormControl>

      <FormControl id="uberlieferung-deutsch" mb={4} isRequired>
        <FormLabel>Überlieferung Deutsch</FormLabel>
        <Textarea
          value={uberlieferungDeutsch}
          onChange={(e) => setUberlieferungDeutsch(e.target.value)}
          placeholder="Enter Überlieferung in Deutsch"
        />
      </FormControl>

      <FormControl id="uberlieferung-arabisch" mb={4} isRequired>
        <FormLabel>Überlieferung Arabisch</FormLabel>
        <Textarea
          value={uberlieferungArabisch}
          onChange={(e) => setUberlieferungArabisch(e.target.value)}
          placeholder="Enter Überlieferung in Arabisch"
        />
      </FormControl>

      <Button type="submit" colorScheme="teal" mt={4} disabled={isSubmitDisabled}>
        Aktualisieren
      </Button>
    </Box>
  );
};

export default HadithForm;
