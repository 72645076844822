import React from "react";
import { Outlet } from "react-router-dom";

function MeetingVerwaltung() {
  return (
    <>
      <div>MeetingVerwaltung</div>
    </>
  );
}

export default MeetingVerwaltung;
