import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import SchulerForm from "./SchulerForm";
import IZRButton from "../IZRButton";
interface props {
  onCloseExtern: () => void;
  name?: string;
  open: boolean;
}

function FormModal({ name, open, onCloseExtern }: props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = React.useRef(null);
  useEffect(() => {
    open && onOpen();
    !open && onClose();
  }, [open]);
  const handleClose = () => {
    onCloseExtern();
    onClose();
  };

  return (
    <>
      <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontFamily={"Poppins"}>
            Daten von {name} beartbeiten
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SchulerForm onSubmit={handleClose} name={name} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default FormModal;
