import {
  Select,
  VStack,
  Text,
  Heading,
  Input,
  Stack,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import AppListItem from "./AppListItem";
import IZRButton from "../IZRButton";
import colors from "../styles/colors";
import izr_server from "../../config/urls";

interface PushToken {
  token: string;
  os: string;
}

interface Resp {
  tokens: PushToken[];
}

function Notifications() {
  const [userNumber, setuserNumber] = useState(0);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [resp, setResp] = useState(null);
  const [IOS, setIOS] = useState(0);
  const [AND, setAND] = useState(0);
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    axios.get<Resp>(izr_server.url + "/pushtokens").then((response) => {
      setuserNumber(response.data.tokens.length);
      let ios = 0;
      let android = 0;
      let tokens: string[] = [];
      response.data.tokens.forEach((token) => {
        token.os === "android" ? android++ : ios++;
      });
      setIOS(ios);
      setAND(android);
    });
  }, []);

  const pushNotificationTest = async () => {
    if (title === "" && subtitle === "") {
      setErrorText("Bitte überprüfen Sie ihre Eingabe");
    } else {
      setDisabled(true);
      await axios
        .post(izr_server.url + "/pushtokens/push", {
          title: title,
          body: subtitle,
        })
        .then((response) => {
          console.log("Server Response : ", response.data);
          setResp(response.data.status);
          setTitle("");
          setSubtitle("");
          setDisabled(false);
        });
    }
  };
  return (
    <Stack
      width={"100%"}
      flex="flex"
      direction={"column"}
      justifyContent="left"
    >
      <Heading>Benachrichtigung an alle senden</Heading>

      <VStack>
        <Input
          focusBorderColor={"green.500"}
          placeholder={"Titel der Benachrichtigung . . ."}
          onChange={(event) => {
            setTitle(event.target.value);
            setErrorText("");
          }}
          value={title}
        />
        <Input
          focusBorderColor="green.500"
          placeholder="Untertitel der Benachrichtigung . . ."
          onChange={(event) => {
            setSubtitle(event.target.value);
            setErrorText("");
          }}
          value={subtitle}
        />
        <Text color={"red"}>{errorText}</Text>
        <IZRButton
          isDisabled={disabled}
          title="Senden"
          onClick={() => pushNotificationTest()}
        />
        {resp && <Text color={"green"}>{resp}</Text>}
      </VStack>

      <Heading>Anzahl der Benutzer der App</Heading>
      <HStack flex={"flex"} justifyContent={{ base: "center" }} spacing={10}>
        <Text color={colors.primary} fontSize={{ lg: 160, base: 80 }}>
          {userNumber}{" "}
        </Text>
        <Text fontSize={{ lg: 90, base: 40 }}>Benutzer </Text>
      </HStack>
      <Stack
        flexDirection={{ base: "column", lg: "row" }}
        justifyContent={"space-around"}
      >
        <Heading>iOS : {IOS}</Heading>
        <Heading>Android : {AND}</Heading>
      </Stack>
    </Stack>
  );
}

export default Notifications;
