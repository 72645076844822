import axios from "axios";
import React, { useEffect, useState } from "react";
import izr_server from "../../config/urls";
import { student } from "./SchuleVerwaltung";
import EditableList from "./EditableList";
import EditableTableRow from "./EditableTableRow";
import FormModal from "./FormModal";

interface props {
  currentYear: string;
  schultag: string;
  onPräsenz: boolean;
  Klasse: number;
  setKlasse: (kl: number) => void;
}

function Schueler({
  currentYear,
  schultag,
  onPräsenz,
  Klasse,
  setKlasse,
}: props) {
  const [Name, setName] = useState("");
  const [onSchulerEdit, setSchulerEdit] = useState(false);
  const [presentStudents, setPresentStudents] = useState<string[]>([]);
  const [allStudents, setAllStudents] = useState<student[]>([]);
  const [students, setStudents] = useState<student[]>([]);
  // Schüler Api
  const handleOnEdit = (name: string) => {
    setName(name);
    setSchulerEdit(true);
  };
  const loadStudents = () => {
    axios.get(izr_server.url + "/getStudents/").then((response) => {
      setAllStudents(response.data.students);
      setStudents(response.data.students);
    });
  };
  const filterStudents = () => {
    Klasse &&
      setStudents(allStudents.filter((student) => student.Klasse === Klasse));
    !Klasse && setStudents(allStudents);
  };
  useEffect(() => {
    setKlasse(0);
    axios
      .post(izr_server.url + "/getStudents/", { do: "get" })
      .then((response) => {
        setAllStudents(response.data.students);
        setStudents(response.data.students);
      });

    console.log(currentYear);
    // setKlasse(0);
  }, [currentYear]);
  useEffect(() => {
    filterStudents();
  }, [Klasse]);

  useEffect(() => {
    const qeuery = {
      do: "get",
      date: schultag,
    };
    console.log(qeuery);
    axios
      .post(izr_server.url + "/presens/", qeuery)
      .then((reso) => setPresentStudents(reso.data["schueler"]));
  }, [schultag, Klasse]);
  return (
    <>
      <EditableList
        keys={
          onPräsenz
            ? ["Name", "Vorame", "Klasse"]
            : ["Name", "Vorame", "Geburtsdatum", "Telefon", "Klasse"]
        }
      >
        {students.map((student, index) => (
          <EditableTableRow
            key={index}
            student_data={{
              Name: student.Name,
              Vorname: student.Vorname,
              Gebutsdatum: student.Gebutsdatum,
              Telefon: student.Telefon,
              Klasse: student.Klasse,
            }}
            onEdit={(name) => handleOnEdit(name)}
            keys={
              onPräsenz
                ? ["Name", "Vorname", "Klasse"]
                : ["Name", "Vorname", "Gebutsdatum", "Telefon", "Klasse"]
            }
            presens={onPräsenz}
            schultag={schultag}
            // updateList={handleUpdate}
            presentStudents={presentStudents}
            klasse={Klasse}
          />
        ))}
      </EditableList>
      <FormModal
        name={Name}
        onCloseExtern={() => setSchulerEdit(false)}
        open={onSchulerEdit}
      />
    </>
  );
}

export default Schueler;
