import { Button, VStack } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";

function ScreenController(): JSX.Element {
  const [messages, setMessages] = useState<string[]>([]);
  const [inputMessage, setInputMessage] = useState<string>("");
  const [ws, setWs] = useState<WebSocket | null>(null);
  const [buttons, setButtons] = useState([
    {
      title: "Gebetszeiten",
      cmd: "prayer?",
      selec: false,
    },
    {
      title: "IZR Webseite",
      cmd: "izr?",
      selec: false,
    },
    {
      title: "APP Werbung",
      cmd: "app?",
      selec: false,
    },
  ]);

  useEffect(() => {
    // Establishing WebSocket connection
    const newWs = new WebSocket("ws://192.168.178.59:8001");

    newWs.onopen = () => {
      console.log("WebSocket connected");
    };

    newWs.onmessage = (event: MessageEvent) => {
      // Received a message from the server
      const message = event.data;
      setMessages((prevMessages) => [...prevMessages, message]);
    };

    newWs.onclose = () => {
      console.log("WebSocket disconnected");
    };

    setWs(newWs);

    // Cleanup function to close the WebSocket connection
    return () => {
      newWs.close();
    };
  }, []); // Empty dependency array to run this effect only once on component mount

  const sendMessage = (cmd: string) => {
    if (ws && cmd !== "") {
      ws.send(cmd);
    }
  };

  return (
    <VStack fontFamily={"Poppins"} width={"100%"} padding={5} gap={5}>
      {buttons.map((b) => (
        <Button
          height={"30vh"}
          width={"100%"}
          onClick={() => {
            sendMessage(b.cmd);
          }}
          colorScheme="blue"
          variant={b.selec ? "solid" : "outline"}
          fontSize={"10vw"}
        >
          {b.title}
        </Button>
      ))}
    </VStack>
  );
}

export default ScreenController;
