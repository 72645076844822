import {
  Button,
  HStack,
  Heading,
  Input,
  Show,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { MutableRefObject, useRef, useState } from "react";
import { Textarea } from "@chakra-ui/react";
import { Switch } from "@chakra-ui/react";
import colors from "../styles/colors";
import { color } from "framer-motion";
import FormData from "form-data";
import axios from "axios";
import izr_server from "../../config/urls";

interface props {
  onSubmit: (title: string, subtitle: string) => void;
}

export function EventUploadLayout({ onSubmit }: props) {
  const [TextDescription, setTextDescription] = useState(true);
  const [notify, setNotify] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [title, setTitle] = useState("");
  const [title_ar, setTitle_Ar] = useState("");
  const [subtitle, setSubTitle] = useState("");
  const [subtitle_ar, setSubTitle_Ar] = useState("");
  const [Description, setDescription] = useState("");
  const [Description_ar, setDescription_Ar] = useState("");

  const [link, setLink] = useState("");
  const [flyer_de, setFlyer_De] = useState<FileList | null>();
  const [flyer_ar, setFlyer_Ar] = useState<FileList | null>();
  const [flyerTV, setFlyerTV] = useState<FileList | null>();

  const handleUpload = async () => {
    setSubmitting(true);
    // file && console.log("file : " + file[0].size);
    let data = new FormData();
    data.append("title", title);
    data.append("title_ar", title_ar);
    data.append("subtitle", subtitle);
    data.append("subtitle_ar", subtitle_ar);
    data.append("link", link);
    flyer_de && data.append("file", flyer_de[0]);
    flyer_ar && data.append("file", flyer_ar[0]);
    flyerTV && data.append("file", flyerTV[0]);
    data.append("description", Description);
    data.append("description_ar", Description_ar);
    notify &&
      (await axios
        .post(izr_server.url + "/pushNotification", {
          tokens: "all",
          title: title,
          subtitle: subtitle,
        })
        .then((response) => {
          console.log(response);
        }));

    await axios
      .post(izr_server.url + "/getEvents", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        maxContentLength: 16 * 1024 * 1024,
      })
      .then((response) => {
        console.log(response.data);
        setTitle("");
        setTitle_Ar("");
        setSubTitle("");
        setSubTitle_Ar("");
        setDescription("");
        setDescription_Ar("");
        setLink("");
        setSubmitting(false);
      });
  };
  const handleChange = (what_is_chnaged: string) => {};
  return (
    <Stack width={"100%"} spacing={3}>
      <Heading>Veranstalung Hinzufügen</Heading>
      <Input
        focusBorderColor="green.500"
        placeholder="name der Vertanstaltung "
        onChange={(event) => setTitle(event.target.value)}
        value={title}
      />
      <Input
        focusBorderColor="green.500"
        placeholder="اسم الحدث"
        onChange={(event) => setTitle_Ar(event.target.value)}
        value={title_ar}
        textAlign={"right"}
      />
      <Input
        focusBorderColor="green.500"
        placeholder="Untertitle der Vertanstaltung "
        onChange={(event) => setSubTitle(event.target.value)}
        value={subtitle}
      />{" "}
      <Input
        focusBorderColor="green.500"
        placeholder="العنوان الفرعي للحدث"
        onChange={(event) => setSubTitle_Ar(event.target.value)}
        value={subtitle_ar}
        textAlign={"right"}
      />
      <Textarea
        focusBorderColor="green.500"
        placeholder="Anmeldungslink der Vertanstaltung falls vorhanden  "
        flexWrap={"wrap"}
        onChange={(event) => setLink(event.target.value)}
        value={link}
      />
      <Heading fontSize={22}>Flyer auswählen</Heading>
      <Heading fontSize={20}>App Flyer Deustch</Heading>
      <Input
        onChange={(event) => setFlyer_De(event.target.files)}
        type="file"
      />
      <Heading fontSize={20}>App Flyer Arabisch</Heading>
      <Input
        onChange={(event) => setFlyer_Ar(event.target.files)}
        type="file"
      />
      <Heading fontSize={20}>IZR Bildschirmflyer</Heading>
      <Input onChange={(event) => setFlyerTV(event.target.files)} type="file" />
      <Heading fontSize={22}>Beschreibung der Veranstaltung</Heading>
      <Heading fontSize={22}>Deutsch</Heading>
      <Textarea
        focusBorderColor="green.500"
        placeholder="bitte die Veranstlatung ausführlich Beschreiben (Datum, Zeit, Ort, Angebote, Kosten, Ameldungskosten usw . . .) "
        flexWrap={"wrap"}
        height={200}
        onChange={(event) => setDescription(event.target.value)}
        value={Description}
      />
      v<Heading fontSize={22}>Arabisch</Heading>
      <Textarea
        focusBorderColor="green.500"
        placeholder="يرجى وصف الحدث بالتفصيل (التاريخ، الوقت، الموقع، العروض، التكاليف، تكاليف التسجيل، وما إلى ذلك.)"
        flexWrap={"wrap"}
        textAlign={"right"}
        height={200}
        onChange={(event) => setDescription_Ar(event.target.value)}
        value={Description_ar}
      />
      <Text>Die Nutzer Benachrichtigen</Text>
      <Switch
        width={8}
        onChange={() => {
          setNotify(!notify);
          console.log(notify ? "notify" : "do not notify");
        }}
      />
      <Button
        _hover={{ backgroundColor: colors.sec, color: "black" }}
        onClick={() => {
          handleUpload();
          onSubmit(title, subtitle);
        }}
        color="white"
        backgroundColor={colors.primary}
        isLoading={submitting}
        loadingText={"wird hinzugefügt..."}
        isDisabled={flyer_de && flyer_ar && title && title_ar ? false : true}
      >
        Hinzufügen
      </Button>
    </Stack>
  );
}
