import React, { useEffect, useState } from "react";
import NavBar from "../NavBar";
import { HStack, Show, Stack, VStack } from "@chakra-ui/react";
import EventsList from "./EventsList";
import styles from "../styles/common.module.css";
import { EventUploadLayout } from "./EventUploadLayout";
import axios from "axios";
import { json } from "stream/consumers";
import Notifications from "./Notifications";
import { Outlet } from "react-router-dom";
import izr_server from "../../config/urls";
export interface event {
  id: string;
  title: string;
  flyer: string;
}

interface resp {
  events: event[];
}

interface token {
  token: string;
}

interface rsp {
  tokens: token[];
}

function AppVerwaltung() {
  const [events, setEvents] = useState<event[]>();
  const [tokens, setTokens] = useState<token[]>([]);

  const getTokens = async () => {
    await axios.get<rsp>(izr_server.url + "/PushToken").then((response) => {
      setTokens(response.data.tokens);
      // console.log("these are my tokens :" + tokens);
    });
  };

  const getEvents = async () => {
    await axios
      .get<resp>(izr_server.url + "/getEvents/all")
      .then((response) => {
        setEvents(response.data.events);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getEvents();
    getTokens();
  }, []);

  const sendNotifications = async (title: string, subtitle: string) => {
    const token_list: string[] = [];
    await tokens.map((token) => token_list.push(token.token));
    await axios
      .post(izr_server.url + "/notify", {
        to: token_list,
        title: "Islamisches Zentrum Regensburg",
        sound: "default",
        body: title + "\n" + subtitle,
      })
      .then((response) => console.log(response));
  };

  const onSubmit = (title: string, subtitle: string) => {
    getTokens();
    sendNotifications(title, subtitle);
    setInterval(() => getEvents(), 500);
  };

  const handleDeleteEvent = (id: string) => {
    console.log(id);
    axios
      .post(izr_server.url + "/deleteEvents/", { id: id })
      .then((response) => {
        console.log(response.data);
      });
    setInterval(() => getEvents(), 500);
  };
  const handleDeleteEvents = () => {
    setEvents([]);
    axios
      .get(izr_server.url + "/deleteEvents/")
      .then((response) => console.log(response.data));
  };
  return (
    <>
      <NavBar page="IZR-APP Verwaltung"></NavBar>;
      <Stack
        className={styles["font-formatted"]}
        // width={{ lg: "100%" }}
        paddingX={{ base: 5, lg: 100 }}
        alignItems={"start"}
        paddingY={100}
        direction={{ base: "column", lg: "row" }}
        spacing={10}
      >
        <Stack
          spacing={10}
          direction={{ base: "column", lg: "column" }}
          width={"100%"}
        >
          <EventsList
            onDeleteEvent={handleDeleteEvent}
            onDeleteEvents={handleDeleteEvents}
            events={events!}
          />
          <Show above="xl">
            <EventUploadLayout onSubmit={onSubmit} />
          </Show>
        </Stack>
        <Notifications />
      </Stack>
    </>
  );
}

export default AppVerwaltung;
