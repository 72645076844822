import React, { useEffect, useState } from "react";
import izr_server from "../../config/urls";
import axios from "axios";
import {
  Button,
  ButtonGroup,
  Heading,
  IconButton,
  SimpleGrid,
  VStack,
} from "@chakra-ui/react";
import { PhoneIcon, DeleteIcon, WarningIcon } from "@chakra-ui/icons";

import AddPopUpForm from "./AddPopUpForm";

function JahreKlassenVerwaltung() {
  const [jahre, setJahre] = useState<string[]>([]);
  const [selectedJahr, setSelectedJahr] = useState<string | undefined>();
  const [reload, setReload] = useState(false);
  const [klassen, setKlassen] = useState<string[]>([]);
  const [AddPopUp, setAddPopUp] = useState({ value: "", show: false });

  const handleJahre = (jahr: string) => {
    setSelectedJahr(jahr);
  };
  const handleAdd = (add_what: string, value: string) => {
    setAddPopUp({ value: "", show: false });
    console.log("adding " + add_what + " " + value);
    if (value !== "nix") {
      switch (add_what) {
        case "Klassen":
          addKlasse(value);
          break;
        case "Jahr":
          addJahr(value);
          break;
      }
    }
  };

  const handleJahrDelete = (jahr: string) => {
    axios
      .post(izr_server.url + "/schuljahr/", {
        do: "del_jahr",
        value: jahr,
      })
      .then((res) => {
        console.log(res.data);
        setReload(!reload);
      });
  };
  const handleKlasseDelete = (klasse: string) => {
    axios
      .post(izr_server.url + "/schuljahr/", {
        do: "del_klasse",
        jahr: selectedJahr,
        value: Number(klasse),
      })
      .then((res) => {
        console.log(res.data);
        setReload(!reload);
      });
  };

  const addKlasse = (value: string) => {
    axios
      .post(izr_server.url + "/schuljahr/", {
        do: "add_klasse",
        jahr: selectedJahr,
        value: Number(value),
      })
      .then((res) => {
        console.log(res.data);
        setReload(!reload);
      });
  };
  const addJahr = (value: string) => {
    axios
      .post(izr_server.url + "/schuljahr/", {
        do: "add_jahr",
        value: value,
      })
      .then((res) => {
        console.log(res.data);
        setReload(!reload);
      });
  };
  useEffect(() => {
    axios
      .post(izr_server.url + "/schuljahr/", {
        do: "get_jahre",
      })
      .then((response) => {
        setJahre(response.data.jahre);
        !selectedJahr && setSelectedJahr(response.data.jahre[0]);
        console.log(response.data.jahre);
      });
  }, [reload]);
  useEffect(() => {
    axios
      .post(izr_server.url + "/schuljahr/", {
        do: "get_klassen",
        jahr: selectedJahr,
      })
      .then((response) => setKlassen(response.data.klassen));
  }, [selectedJahr, reload]);

  return (
    <VStack fontFamily={"Poppins"}>
      <Heading fontFamily={"Poppins"} width={"100%"}>
        Schuljahre
      </Heading>
      <SimpleGrid padding={5} width={"100%"} gap={5} columns={5}>
        {jahre &&
          jahre.map((jahr) => (
            <ButtonGroup
              colorScheme={"blue"}
              size={"lg"}
              isAttached
              variant="outline"
            >
              <Button
                onClick={() => handleJahre(jahr)}
                width={"100%"}
                variant={selectedJahr === jahr ? "solid" : "outline"}
                borderRight={"0px"}
              >
                {jahr}
              </Button>
              <IconButton
                onClick={() => handleJahrDelete(jahr)}
                aria-label="Add to friends"
                borderLeft={"0px"}
                icon={<DeleteIcon />}
              />
            </ButtonGroup>
          ))}
        <Button
          onClick={() => setAddPopUp({ value: "Jahr", show: true })}
          colorScheme={"green"}
          size={"lg"}
        >
          +
        </Button>
      </SimpleGrid>
      <Heading
        fontFamily={"Poppins"}
        padding={5}
        width={"100%"}
        borderTop={"1px dashed lightgray"}
      >
        Klassen des Jahres {selectedJahr}
      </Heading>
      <SimpleGrid padding={5} width={"100%"} gap={5} columns={5}>
        {klassen &&
          klassen.map((klassen) => (
            <ButtonGroup
              colorScheme={"blue"}
              size={"lg"}
              isAttached
              variant="outline"
            >
              <Button colorScheme={"blue"} size={"lg"}>
                Klasse{klassen}
              </Button>
              <IconButton
                onClick={() => handleKlasseDelete(klassen)}
                aria-label="Add to friends"
                borderLeft={"0px"}
                icon={<DeleteIcon />}
              />
            </ButtonGroup>
          ))}
        <Button
          onClick={() => setAddPopUp({ value: "Klassen", show: true })}
          colorScheme={"green"}
          size={"lg"}
        >
          +
        </Button>
      </SimpleGrid>
      <AddPopUpForm
        onSubmit={(value) => handleAdd(AddPopUp.value, value)}
        value={AddPopUp.value}
        show={AddPopUp.show}
      />
    </VStack>
  );
}

export default JahreKlassenVerwaltung;
