import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Heading,
  Input,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { on } from "stream";

interface props {
  value: string;
  show: boolean;
  onSubmit: (inValue: string) => void;
}

function AddPopUpForm({ value, show, onSubmit }: props) {
  const [InValue, setInValue] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    console.log("show : " + show);
    show && onOpen();
    !show && onClose();
  }, [show]);
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          onSubmit("nix");
        }}
      >
        <ModalOverlay />
        <ModalContent fontFamily={"Poppins"}>
          <ModalHeader>{value} Hinzufügen</ModalHeader>
          <ModalCloseButton />
          <ModalBody></ModalBody>
          <VStack margin={5}>
            <Heading fontFamily={"Poppins"}>{value}:</Heading>
            <Input
              type={"number"}
              onChange={(e) => setInValue(e.currentTarget.value)}
            ></Input>
          </VStack>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                onClose();
                console.log(InValue);
                onSubmit(InValue);
              }}
            >
              Hinzufügen
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AddPopUpForm;
