import { HStack, VStack, Text, Input, Heading } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import izr_server from "../config/urls";
import IZRButton from "./IZRButton";

interface iqamas {
  Asr: Number;
  Dhuhr: Number;
  Fajr: Number;
  Isha: Number;
  Jumaa: Number;
  Maghrib: Number;
}

function IqamaZeiten() {
  const salahs = ["Fajr", "Dhuhr", "Asr", "Maghrib", "Isha", "Jumaa"];
  const [iqamaTimes, setIqamaTimes] = useState<iqamas>();
  const [NewIqamaTimes, setNewIqamaTimes] = useState<iqamas>();
  const [tarawih, setTarawih] = useState("");
  const [NewTarawih, setNewTarawih] = useState("");
  const [disable, setDisable] = useState(false);
  const loadIqamaTimes = () => {
    axios.get(izr_server.url + "/getPrayers/next").then((response) => {
      setIqamaTimes(response.data.iqamah);
      setNewIqamaTimes(response.data.iqamah);
      setTarawih(response.data.tarawih);
      setNewTarawih(response.data.tarawih);
      console.log(response.data.tarawih);
    });
  };
  useEffect(() => {
    loadIqamaTimes();
  }, []);
  const handleSubmit = () => {
    setDisable(true);
    axios
      .post(izr_server.url + "/getPrayers/iqama", {
        iqamah: { ...NewIqamaTimes },
        tarawih: NewTarawih,
      })
      .then((response) => {
        console.log(response.data);
      });
    console.log(NewIqamaTimes);
    loadIqamaTimes();
    setTimeout(() => {
      setDisable(false);
    }, 3000);
  };
  return (
    <VStack justifyContent={"lef"}>
      <Heading fontFamily={"Poppins"} width={"100%"}>
        Iqama Zeiten Ändern
      </Heading>
      {salahs.map((i) => (
        <HStack fontWeight={"bold"} width={"70%"}>
          <Text width={"50%"}>{i}</Text>
          {iqamaTimes && (
            <Input
              key={i}
              fontFamily={"Poppins"}
              fontWeight={"bold"}
              type={"number"}
              defaultValue={iqamaTimes[i as keyof iqamas].toString()}
              textAlign={"center"}
              onChange={(e) =>
                setNewIqamaTimes({
                  ...NewIqamaTimes!,
                  [i as keyof iqamas]: Number(e.currentTarget.value),
                })
              }
            />
          )}
        </HStack>
      ))}
      <HStack fontWeight={"bold"} width={"70%"}>
        <Text width={"50%"}>Tarawih</Text>
        <Input
          fontFamily={"Poppins"}
          fontWeight={"bold"}
          type={"time"}
          defaultValue={tarawih}
          textAlign={"center"}
          onChange={(e) => setNewTarawih(String(e.currentTarget.value))}
        />
      </HStack>
      <IZRButton
        isDisabled={disable}
        title="Akutalisieren"
        onClick={handleSubmit}
      />
    </VStack>
  );
}

export default IqamaZeiten;
