import { GridItem, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";
import IqamaZeiten from "./IqamaZeiten";
import NavBar from "./NavBar";
import RamadanModus from "./RamadanModus";
import HadithForm from "./HadithForm";

function BilschirmConfig() {
  return (
    <>
      <NavBar page="IZR-Bidschirm Konfiguration"></NavBar>;
      <SimpleGrid
        padding={5}
        gap={5}
        width={"100vw"}
        columns={{ lg: 1, base: 1 }}
      >
        <GridItem
          border={"1px solid lightgray"}
          borderRadius={10}
          padding={5}
          shadow={"0px 0px 7px 0px lightgray "}
        >
          <IqamaZeiten />
        </GridItem>

        <GridItem
          border={"1px solid lightgray"}
          borderRadius={10}
          padding={5}
          shadow={"0px 0px 10px 0px lightgray"}
        >
          <RamadanModus />
        </GridItem>
        <GridItem
          border={"1px solid lightgray"}
          borderRadius={10}
          padding={5}
          shadow={"0px 0px 10px 0px lightgray"}
        >
          <HadithForm />
        </GridItem>
      </SimpleGrid>
    </>
  );
}

export default BilschirmConfig;
