import { Button, Td, Tr, Text } from "@chakra-ui/react";
import React, { ReactNode, useEffect, useState } from "react";
import SchulerForm from "./SchulerForm";
import axios from "axios";
import izr_server from "../../config/urls";

interface student {
  Name: string;
  Vorname: string;
  Telefon: string;
  Gebutsdatum: string;
  Klasse: number;
}

interface student_key {
  Name: string;
  Vorname: string;
  Telefon: string;
  Gebutsdatum: string;
  Klasse: number;
}

interface props {
  onEdit: (name: string) => void;
  student_data: student;
  keys: string[];
  presens: boolean;
  schultag: string;
  klasse: number;
  presentStudents: string[];
}

interface resp {
  date: string;
  schueler: string[];
}

function EditableTableRow({
  onEdit,
  student_data,
  keys,
  presens,
  schultag,
  presentStudents,
  klasse,
}: props) {
  const [button, showButton] = useState(false);
  const [present, setPresent] = useState<boolean>(false);

  useEffect(() => {
    // if (presentStudents !== undefined) {
    if (
      presentStudents.find(
        (data) => data === student_data.Name + "_" + student_data.Gebutsdatum
      ) !== undefined
    ) {
      setPresent(true);
    } else {
      setPresent(false);
    }
    // }
    console.log("updating");
  }, [schultag, klasse, presentStudents]);

  const handlePresens = () => {
    axios.post<resp>(izr_server.url + "/presens/", {
      do: present ? "del" : "add",
      date: schultag,
      presens_data: student_data.Name + "_" + student_data.Gebutsdatum,
    });
  };
  return (
    <>
      <Tr
        onMouseOver={() => showButton(true)}
        onMouseLeave={() => showButton(false)}
        onClick={() => console.log(student_data, present)}
      >
        {keys.map((key) => (
          <Td key={key}>{student_data[key as keyof typeof student_data]}</Td>
        ))}
        {!presens && (
          <Td width={"10%"}>
            <Button
              onClick={() => onEdit(student_data.Vorname)}
              opacity={button ? "100%" : "0%"}
              colorScheme="teal"
              size="xs"
            >
              Bearbeiten
            </Button>
          </Td>
        )}
        {presens && (
          <Td width={"10%"}>
            <Button
              backgroundColor={present ? "green.500" : "red.500"}
              color={"white"}
              _hover={{
                color: "black",
                backgroundColor: "gray.200",
              }}
              onClick={() => {
                setPresent(!present);
                handlePresens();
              }}
            >
              Präsenst : {present ? "JA" : "NEIN"}
            </Button>
          </Td>
        )}
      </Tr>
    </>
  );
}

export default EditableTableRow;
