import {
  Button,
  FormControl,
  Grid,
  GridItem,
  HStack,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import IZRButton from "../IZRButton";
import FormModal from "./FormModal";
import axios from "axios";
import izr_server from "../../config/urls";

interface props {
  onJahrSelect: (jahr: string) => void;
  onChnageDay: (day: string) => void;
  onPräsenz: () => void;
  onBearbeiten: () => void;
  onChangeClass: (klasse: number) => void;
  klasse: number;
  presense: boolean;
  what: string;
}

function SchuleParams({
  onJahrSelect,
  onBearbeiten,
  onPräsenz,
  onChangeClass,
  onChnageDay,
  klasse,
  presense,
  what,
}: props) {
  // const jahre = ["2024", "2025", "2026", "2027", "2028", "2029"];

  const [klassen, setKlassen] = useState<number[]>([]);
  const [currentDay, setCurrentDay] = useState("");
  const [jahre, setJahre] = useState<string[]>();
  const [CurrentJahr, setCurrentJahr] = useState("");
  const [dates, setDates] = useState<string[]>([
    new Date().getMonth() +
      1 +
      "-" +
      new Date().getDate() +
      "-" +
      new Date().getFullYear(),
  ]);
  const [currentDayIndex, setCurrentDayIndex] = useState(0);

  const [hinzu, setHinzu] = useState(false);
  useEffect(() => {
    axios
      .post(izr_server.url + "/schuljahr/", {
        do: "get_klassen",
        jahr: CurrentJahr,
      })
      .then((response) => setKlassen(response.data.klassen));
  }, [CurrentJahr]);
  useEffect(() => {
    axios
      .post(izr_server.url + "/schuljahr/", {
        do: "get_jahre",
      })
      .then((reso) => {
        setJahre(reso.data.jahre);
        setCurrentJahr(reso.data.current);
        onJahrSelect(reso.data.current);
        console.log(reso.data.current);
      });
  }, []);

  const hanldleJahrSelect = (jahr: string) => {
    setCurrentJahr(jahr);
    axios.post(izr_server.url + "/schuljahr/", {
      do: "set_current_jahr",
      new_jahr: jahr,
    });
    onJahrSelect(jahr);
    console.log("on jahr select");
  };

  useEffect(() => {
    axios
      .post<{ schultage: string[] }>(izr_server.url + "/presens/", {
        do: "get_dates",
      })
      .then((reso) => {
        if (reso.data.schultage.length > 0) {
          const new_dates = reso.data.schultage;
          const heute =
            new Date().getMonth() +
            1 +
            "-" +
            new Date().getDate() +
            "-" +
            new Date().getFullYear();
          if (new_dates.find((d) => d === heute)) {
            setDates(new_dates);
            setCurrentDayIndex(new_dates.length - 1);
            // console.log(reso.data.schultage.length);
          } else {
            setCurrentDayIndex(new_dates.length);
            new_dates.push(heute);
            console.log(new_dates);
            setDates(new_dates);
          }
        }
      });
  }, []);

  useEffect(() => {
    const date = dates[currentDayIndex];
    const d = new Date(date).toLocaleDateString("de-DE", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
    console.log(d);
    setCurrentDay(d);
    onChnageDay(dates[currentDayIndex]);
    console.log(currentDayIndex);
  }, [currentDayIndex]);
  return (
    <VStack width={"100%"}>
      <HStack width={"100%"}>
        <Text fontWeight={"bold"}>Jahr</Text>
        <Select
          value={CurrentJahr}
          onChange={(e) => hanldleJahrSelect(e.currentTarget.value)}
        >
          {jahre &&
            jahre.map((jahr, index) => (
              <option key={index} value={jahr}>
                {jahr}
              </option>
            ))}
        </Select>
        <IZRButton title="Präsens eintragen" onClick={onPräsenz}></IZRButton>
        {what === "Schüler" && (
          <IZRButton title="Zahlungen" onClick={onPräsenz}></IZRButton>
        )}
        <IZRButton
          title={what + " Daten beartbeiten"}
          onClick={onBearbeiten}
        ></IZRButton>
      </HStack>
      {what === "Schüler" && (
        <HStack width={"100%"}>
          <Text fontWeight={"bold"}>Klassen:</Text>
          <Button
            colorScheme={"blue"}
            variant={0 === klasse ? "solid" : "outline"}
            width={"100%"}
            onClick={() => onChangeClass(0)}
          >
            {klassen.length ? "Alle" : "Noch keine Klasen"}
          </Button>
          {klassen.map((kl, index) => (
            <Button
              colorScheme={"blue"}
              variant={kl === klasse ? "solid" : "outline"}
              width={"100%"}
              key={index}
              onClick={() => onChangeClass(kl)}
            >
              {"Klasse" + kl}
            </Button>
          ))}
        </HStack>
      )}
      {!presense && (
        <>
          <IZRButton
            title="Schüler Hinzufügen"
            onClick={() => setHinzu(true)}
          ></IZRButton>
          <FormModal
            onCloseExtern={() => setHinzu(false)}
            open={hinzu}
          ></FormModal>
        </>
      )}
      {presense && (
        <HStack width={"100%"}>
          <IZRButton
            title="<< Voheriger Schultag"
            onClick={() => {
              currentDayIndex > 0
                ? setCurrentDayIndex(currentDayIndex - 1)
                : setCurrentDayIndex(currentDayIndex);
            }}
          ></IZRButton>
          <IZRButton
            title={currentDay}
            onClick={() => setHinzu(true)}
          ></IZRButton>
          <IZRButton
            title="Nächster Schultag  >>"
            onClick={() => {
              currentDayIndex < dates.length - 1
                ? setCurrentDayIndex(currentDayIndex + 1)
                : setCurrentDayIndex(currentDayIndex);
            }}
          ></IZRButton>
        </HStack>
      )}
    </VStack>
  );
}

export default SchuleParams;
