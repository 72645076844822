import { Button, HStack, Text } from "@chakra-ui/react";
import { strict } from "assert";
import axios from "axios";
import React, { useEffect, useState } from "react";
import izr_server from "../config/urls";

function RamadanModus() {
  const [modus, setModus] = useState<string>();
  const ramadanFunc = (what: String, onOff?: string) => {
    if (what === "get") {
      axios.get(izr_server.url + "/getPrayers/ramadan").then((response) => {
        console.log(response.data);
        setModus(response.data.ramadan);
      });
    } else if (what === "post") {
      axios
        .post(izr_server.url + "/getPrayers/ramadan", {
          ramadan: onOff,
        })
        .then((response) => {
          console.log(response.data);
        });
    }
  };

  useEffect(() => {
    ramadanFunc("get");
  }, [modus]);
  const handleClick = () => {
    switch (modus) {
      case "none":
        setModus("on");
        ramadanFunc("post", "on");
        break;
      case "on":
        setModus("off");
        ramadanFunc("post", "off");
        break;
      case "off":
        setModus("on");
        ramadanFunc("post", "on");
        break;
    }
  };
  return (
    <HStack fontFamily={"Poppins"} fontWeight={"bold"} width={"100%"}>
      <Text width={"40%"}>Ramadan Modus</Text>
      {modus && (
        <Button
          width={"100%"}
          onClick={handleClick}
          colorScheme={
            modus === "off" ? "red" : modus === "on" ? "green" : "gray"
          }
        >
          {modus.toUpperCase()}
        </Button>
      )}
    </HStack>
  );
}

export default RamadanModus;
