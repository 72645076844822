import { Button, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import colors from "../styles/colors";

interface props {
  onSwitch: (value: string) => void;
}

function Aside({ onSwitch }: props) {
  const buttons = [
    "Schüler",
    "Lehrer",
    "Schuljahren/Klassen verwalten",
    "Statistik",
    "Zahlungen",
  ];
  const [selected, setSelected] = useState("Schüler");
  const handleClick = (button: string) => {
    setSelected(button);
    onSwitch(button);
  };
  return (
    <VStack gap={10} margin={5}>
      {buttons.map((button) => (
        <Button
          onClick={() => handleClick(button)}
          backgroundColor={selected === button ? colors.sec : colors.primary}
          color={selected === button ? "black" : "white"}
          height={"4vw"}
          width={"100%"}
          _hover={{
            backgroundColor: "gray.100",
            color: "black",
          }}
        >
          {button}
        </Button>
      ))}
    </VStack>
  );
}

export default Aside;
